import Loading from 'components/ui/loading';
import logger from 'lib/logger';
import { StoryBlokContent } from 'lib/storyblok';

// props here contains anything added to props in getStaticProps
// things specific to the story should have been added to the story
const GenericStory = ({ folder, story, preview, ...props }) => {
  if (story && story.slug) {
    const log = logger({ category: `GenericStory:${folder}/${story.slug}` });
    log.silly('story in generic: %o, props: %o, preview', story, props, preview);
    return <StoryBlokContent folder={folder} initialStory={story} preview={preview} origin={log.category} className="ht-generic-story" {...props} />;
  }
  logger({ category: 'GenericStory' }).debug('No story in generic');

  return <Loading />;
};

export default GenericStory;
